import React from 'react';
import { Box, Typography, Button, Grid, Paper } from '@mui/material';
import styles from '../styles/Services.module.css'; // Make sure to create and use this CSS file
import van1 from '../assets/images/photo22.jpg'; // Ensure the image path is correct

const Services = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>
        УСЛУГИ
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Box className={styles.imageContainer}>
            {/* Correctly use the imported image */}
            <img src={van1} alt="Service Van" className={styles.image} />
            {/* Make the button a link to call the phone number */}
            <Button
              className={styles.button}
              href="tel:+359888078383" // Link to the phone number
            >
              Обади се
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={styles.infoContainer}>
            <Typography variant="h6" className={styles.infoTitle}>
              Услуги с Пътна помощ за град Пловдив и страната.
            </Typography>
            <Typography variant="body1" className={styles.infoText}>
              Пътна Помощ и услуги на добри цени! Репатриране и превозване на автомобили, микробуси, джипове, строителна, селскостопанска и специализирана техника. Превоз на автомобили до КАТ и други.
            </Typography>
            <Typography variant="body1" className={styles.emergencyText}>
              При спешност: <a className={styles.emergencyText2} href="tel:+359888078383">+359888078383</a>
            </Typography>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Services;
