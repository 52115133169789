// src/components/RoadAssistancePage.js
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import assistanceData from '../data/assistanceData'; // Import the roadside assistance data
import styles from '../styles/VansPage.module.css'; // CSS module for styling

const RoadAssistancePage = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>Пътна помощ</Typography>
      <Grid container spacing={4}>
        {assistanceData.map((assistance) => (
          <Grid item xs={12} sm={6} md={4} key={assistance.id}>
            <Card className={styles.card}>
              <Link to={`/assistance${assistance.id}`} className={styles.link}>
                <CardMedia
                  component="img"
                  alt={assistance.name}
                  height="300"
                  image={assistance.mainImage}
                  className={styles.cardImage}
                />
                <CardContent>
                  <Typography variant="h5" className={styles.cardTitle}>{assistance.name}</Typography>
                  <Typography variant="body2" color="textSecondary" className={styles.cardSubtitle}>
                    {assistance.year}
                  </Typography>
                  <Button variant="contained" className={styles.button}>Виж повече</Button>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RoadAssistancePage;
