import React from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { Box, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const LightboxContainer = styled(Box)({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.9)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1300,
});

const LightboxImage = styled('img')({
  maxWidth: '90%',
  maxHeight: '90%',
  borderRadius: '8px',
});

const Lightbox = ({ images, photoIndex, setPhotoIndex, onClose }) => {
  const handlePrev = () => {
    setPhotoIndex((photoIndex + images.length - 1) % images.length);
  };

  const handleNext = () => {
    setPhotoIndex((photoIndex + 1) % images.length);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <LightboxContainer {...swipeHandlers}>
      <IconButton
        onClick={onClose}
        style={{ position: 'absolute', top: '16px', right: '16px', color: '#fff' }}
      >
        <CloseIcon />
      </IconButton>
      <IconButton
        onClick={handlePrev}
        style={{ position: 'absolute', top: '50%', left: '16px', color: '#fff' }}
      >
        <ArrowBackIosIcon />
      </IconButton>
      <LightboxImage src={images[photoIndex]} alt={`Image ${photoIndex + 1}`} />
      <IconButton
        onClick={handleNext}
        style={{ position: 'absolute', top: '50%', right: '16px', color: '#fff' }}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    </LightboxContainer>
  );
};

Lightbox.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  photoIndex: PropTypes.number.isRequired,
  setPhotoIndex: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Lightbox;
