// src/data/trailerData.js
import trailer1 from '../assets/images/photo30.jpeg';
import trailerGallery0 from '../assets/images/photo30.jpeg';
import trailerGallery1 from '../assets/images/photo31.jpeg';
import trailerGallery2 from '../assets/images/photo32.jpeg';

const trailerData = [
  {
    id: 1,
    name: 'STEMA FT 7525',
    year: 'Година на производство: 2010',
    license: 'Необходима категория шофьорска книжка: Е',
    price: 'Цена на ден от: 50,00 лв. (без ДДС)',
    mainImage: trailer1, // Directly use the imported image
    galleryImages: [trailerGallery0, trailerGallery1, trailerGallery2], // Directly use imported images
    specifications: [
      {
        title: 'Спецификации:',
        details: [
          'Дължина - 3.00м.',
          'Широчина - 1.60м.',
          'Височина - 1.80м.',
          'Товаримост - 900кг.',
        ],
      },
    ],
  },
];

export default trailerData;
