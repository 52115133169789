import React, { useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, Box, Menu, MenuItem, Collapse } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Link, NavLink } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import styles from '../styles/Navbar.module.css';
import logo from '../assets/images/ENTARO.png';
import AnnouncementBar from './AnnouncementBar';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [autoparkOpen, setAutoparkOpen] = useState(false);
  const [conditionsOpen, setConditionsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [conditionsAnchorEl, setConditionsAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery('(max-width: 1100px)');

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleAutoparkClick = (event) => {
    event.stopPropagation();
    setAutoparkOpen(!autoparkOpen);
  };

  const handleConditionsClick = (event) => {
    event.stopPropagation();
    setConditionsOpen(!conditionsOpen);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConditionsMenuClick = (event) => {
    setConditionsAnchorEl(event.currentTarget);
  };

  const handleConditionsMenuClose = () => {
    setConditionsAnchorEl(null);
  };

  const drawerList = (
    <Box
      className={styles.drawerList}
      role="presentation"
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={NavLink} to="/" onClick={toggleDrawer(false)}>
          <ListItemText primary="Начало" />
        </ListItem>
        <ListItem button onClick={handleAutoparkClick}>
          <ListItemText primary="Автопарк" />
          {autoparkOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={autoparkOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={NavLink} to="/vanspage" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary="Товарни Бусове" />
            </ListItem>
            <ListItem button component={NavLink} to="/carspage" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary="Автомобили" />
            </ListItem>
            <ListItem button component={NavLink} to="/roadassistance" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary="Пътна Помощ" />
            </ListItem>
            <ListItem button component={NavLink} to="/trailersection" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary="Ремаркета" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={NavLink} to="/services" onClick={toggleDrawer(false)}>
          <ListItemText primary="Услуги" />
        </ListItem>
        <ListItem button component={NavLink} to="/about" onClick={toggleDrawer(false)}>
          <ListItemText primary="За Нас" />
        </ListItem>
        <ListItem button onClick={handleConditionsClick}>
          <ListItemText primary="Условия" />
          {conditionsOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={conditionsOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button component={NavLink} to="/privacy-policy" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary="Политика за поверителност при отдаване под наем" />
            </ListItem>
            <ListItem button component={NavLink} to="/policy" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary="Условия за наемане" />
            </ListItem>
            <ListItem button component={NavLink} to="/privacy-policy-site" onClick={toggleDrawer(false)} className={styles.nested}>
              <ListItemText primary=" Политика за поверителност на сайта" />
            </ListItem>
          </List>
        </Collapse>
        <ListItem button component={NavLink} to="/contact" onClick={toggleDrawer(false)}>
          <ListItemText primary="Контакти" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AnnouncementBar />
      <AppBar position="static" className={styles.appBar}>
        <Toolbar className={styles.toolbar}>
          {isMobile ? (
            <>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                className={styles.menuButton}
                onClick={toggleDrawer(true)}
                style={{ fontSize: '30px' }}
              >
                <MenuIcon style={{ fontSize: 'inherit' }} />
              </IconButton>
              <Link to="/" className={styles.logoLink}>
                <Box
                  component="img"
                  src={logo}
                  alt="Rentaro Logo"
                  className={styles.mobileLogo}
                />
              </Link>
            </>
          ) : (
            <>
              <Link to="/" className={styles.logoLink}>
                <Box
                  component="img"
                  src={logo}
                  alt="Rentaro Logo"
                  className={styles.desktopLogo}
                />
              </Link>
              <Box className={styles.navButtons}>
                <Button
                  color="inherit"
                  onClick={handleMenuClick}
                  className={`${styles.navButton} ${styles.dropdownButton}`}
                  aria-controls="autopark-menu"
                  aria-haspopup="true"
                >
                  АВТОПАРК
                </Button>
                <Menu
                  id="autopark-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    onMouseLeave: handleMenuClose,
                  }}
                  className={styles.dropdownMenu}
                >
                  <MenuItem component={Link} to="/vanspage" onClick={handleMenuClose}>Товарни бусове</MenuItem>
                  <MenuItem component={Link} to="/carspage" onClick={handleMenuClose}>Автомобили</MenuItem>
                  <MenuItem component={Link} to="/roadassistance" onClick={handleMenuClose}>Пътна помощ</MenuItem>
                  <MenuItem component={Link} to="/trailersection" onClick={handleMenuClose}>Ремаркета</MenuItem>
                </Menu>
                <Button color="inherit" component={Link} to="/services" className={styles.navButton}>
                  УСЛУГИ
                </Button>
                <Button color="inherit" component={Link} to="/about" className={styles.navButton}>
                  ЗА НАС
                </Button>
                <Button
                  color="inherit"
                  onClick={handleConditionsMenuClick}
                  className={`${styles.navButton} ${styles.dropdownButton}`}
                  aria-controls="conditions-menu"
                  aria-haspopup="true"
                >
                  УСЛОВИЯ
                </Button>
                <Menu
                  id="conditions-menu"
                  anchorEl={conditionsAnchorEl}
                  open={Boolean(conditionsAnchorEl)}
                  onClose={handleConditionsMenuClose}
                  MenuListProps={{
                    onMouseLeave: handleConditionsMenuClose,
                  }}
                  className={styles.dropdownMenu}
                >
                  <MenuItem component={Link} to="/privacy-policy" onClick={handleConditionsMenuClose}>Политика за поверителност при отдаване под наем</MenuItem>
                  <MenuItem component={Link} to="/policy" onClick={handleConditionsMenuClose}>Условия за наемане </MenuItem>
                  <MenuItem component={Link} to="/privacy-policy-site" onClick={handleConditionsMenuClose}>Политика за поверителност на сайта</MenuItem>
                </Menu>
                <Button color="inherit" component={Link} to="/contact" className={styles.navButton}>
                  КОНТАКТИ
                </Button>
              </Box>
              <Box className={styles.compareButtonContainer}>
                <Button href="https://maps.google.com/?q=ул.+Гаганица+89а,+Пловдив,+Bulgaria" target="_blank" rel="noopener noreferrer" className={styles.compareButton}>
                  ЛОКАЦИЯ ЗА НАЕМАНЕ
                </Button>
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{ className: styles.drawerPaper }}
      >
        {drawerList}
      </Drawer>
    </>
  );
};

export default Navbar;
