// src/data/vanData.js
import van1 from '../assets/images/photo8.jpeg';
import van2 from '../assets/images/photo5.jpeg'; // Image for Van 2
import van3 from '../assets/images/photo1.jpeg'; // Image for Van 3

// Gallery images for each van
import vanGallery0 from '../assets/images/photo8.jpeg';
import vanGallery1 from '../assets/images/photo9.jpeg';
import vanGallery2 from '../assets/images/photo10.jpeg';
import vanGallery3 from '../assets/images/photo11.jpeg';

import van2Gallery0 from '../assets/images/photo5.jpeg'; // Gallery images for Van 2
import van2Gallery1 from '../assets/images/photo6.jpeg';
import van2Gallery2 from '../assets/images/photo7.jpeg';

import van3Gallery0 from '../assets/images/photo1.jpeg'; // Gallery images for Van 3
import van3Gallery1 from '../assets/images/photo2.jpeg';
import van3Gallery2 from '../assets/images/photo3.jpeg';
import van3Gallery3 from '../assets/images/photo4.jpeg';

import berlingoMainImage from '../assets/images/photo41.jpg';
import berlingoGallery1 from '../assets/images/photo41.jpg';
import berlingoGallery2 from '../assets/images/photo42.jpg';
import berlingoGallery3 from '../assets/images/photo43.jpg';
import berlingoGallery4 from '../assets/images/photo44.jpg';

const vanData = [
  {
    id: 1,
    name: 'Renault Master 2.3 dCi',
    year: 'Година на производство: 2019',
    license: 'Необходима категория шофьорска книжка: B',
    price: 'Цена за наем на ден(24 часа): 80 лв. без ДДС',
    descr: 'Пробег: 350 км.',
    descr1: '(Над пробега - 0.15 лв. на КМ без ДДС)',
    deposit: '300лв',
    mainImage: van1,
    galleryImages: [vanGallery0, vanGallery1, vanGallery2, vanGallery3],
    specifications: [
      {
        title: 'Товарен отсек:',
        details: [
          'Дължина - 3.60м.',
          'Широчина - 1.70м.',
          'Височина - 1.80м.',
          'Полезен обем - 12куб.м.',
          'Товаримост - 1400кг.',
        ],
      },
      {
        title: 'Други:',
        details: [
          'Задвижване: Предно',
          'Вид гориво: Дизел',
          'Европейска екологична норма: Euro 6B',
          'Брой места - 2+1',
          'Скоростна кутия: Механична',
          'Брой предавки: 6',
          'Категория - В',
          'Климатик',
          'Централно заключване',
          'ABS',
          'Стерео уредба',
          'Въздушни възглавници',
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Iveco Daily 35C12',
    year: 'Година на производство: 2019',
    license: 'Необходима категория шофьорска книжка: B',
    price: 'Цена за наем на ден: 100,00 лв. (без ДДС)',
    descr: 'Пробег: 350 км.',
    descr1: '(Над пробега - 0.15 лв. на КМ без ДДС)',
    deposit: '300 лв',
    mainImage: van2,
    galleryImages: [van2Gallery0, van2Gallery1, van2Gallery2],
    specifications: [
      {
        title: 'Товарен отсек:',
        details: [
          'Дължина - 4.60м.',
          'Широчина - 1.70м.',
          'Височина - 1.80м.',
          'Полезен обем - 14куб.м.',
          'Товаримост - 1600кг.',
        ],
      },
      {
        title: 'Други:',
        details: [
          'Задвижване: Предно',
          'Вид гориво: Дизел',
          'Европейска екологична норма: Euro 6B',
          'Брой места - 2+1',
          'Скоростна кутия: Механична',
          'Брой предавки: 6',
          'Категория - В',
          'Климатик',
          'Централно заключване',
          'ABS',
          'Стерео уредба',
          'Въздушни възглавници',
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Volkswagen Crafter 2.0 TDI',
    year: 'Година на производство: 2019',
    license: 'Необходима категория шофьорска книжка: B',
    price: 'Цена за наем на ден: 100,00 лв. (без ДДС)',
    descr: 'Пробег: 350 км.',
    descr1: '(Над пробега - 0.15 лв. на КМ без ДДС)',
    deposit: '300 лв',
    mainImage: van3,
    galleryImages: [van3Gallery0, van3Gallery1, van3Gallery2, van3Gallery3],
    specifications: [
      {
        title: 'Товарен отсек:',
        details: [
          'Дължина - 4.00м.',
          'Широчина - 1.70м.',
          'Височина - 1.80м.',
          'Полезен обем - 12.30куб.м.',
          'Товаримост - 1500кг.',
        ],
      },
      {
        title: 'Други:',
        details: [
          'Задвижване: Предно',
          'Вид гориво: Дизел',
          'Европейска екологична норма: Euro 6B',
          'Брой места - 2+1',
          'Скоростна кутия: Механична',
          'Брой предавки: 6',
          'Категория - В',
          'Климатик',
          'Централно заключване',
          'ABS',
          'Стерео уредба',
          'Въздушни възглавници',
        ],
      },
    ],
  },
  {
    id: 4,
    name: 'Citroen Berlingo',
    year: 'Година на производство: 2009',
    license: 'Необходима категория шофьорска книжка: B',
    deposit: 'Депозит: 500лв',
    price: 'Наем за 24ч: 50 лв. (без ДДС)',
    descr: 'Пробег: 350 км.',
    descr1: '(Над пробега - 0.15 лв. на КМ без ДДС)',
    deposit: 'Депозит : 300 лв',
    mainImage: berlingoMainImage, 
    galleryImages: [berlingoGallery1, berlingoGallery2, berlingoGallery3, berlingoGallery4], 
    specifications: [
      {
        title: 'Товарен отсек:',
        details: [
          'Дължина: 1.50м',
          'Ширина: 1.20м',
          'Височина: 1.20м',
          'Товаримост: 600кг.',
          'Полезен обем: 2.20куб.м.',
        ],
      },
      {
        title: 'Технически характеристики:',
        details: [
          'Задвижване: Предно',
          'Вид гориво: Дизел',
          'Европейска екологична норма: Euro 4',
          'Брой места: 1+1',
          'Скоростна кутия: Механична',
          'Брой предавки: 5',
          'Категория - В',
          'Климатик',
          'Централно заключване',
          'ABS',
          'Стерео уредба',
          'Въздушни възглавници',
        ],
      },
    ],
  },
];

export default vanData;
