import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Vans from './components/Vans';
import Van1 from './components/Van1';
import Van2 from './components/Van2';
import Van3 from './components/Van3';
import Van4 from './components/Van4';
import Car1 from './components/car1';
import Car2 from './components/car2';
import Footer from './components/Footer';
import Policy from './components/Policy';
import VansPage from './components/VansPage';
import PrivacyPolicySite from './components/PrivacyPolicySite';
import Services from './components/Services';
import CarsPage from './components/CarsPage';
import TrailerSection from './components/TrailerSection';
import Trailer1 from './components/Trailer1';
import RoadAssistancePage from './components/RoadAssistancePage';
import Assistance1 from './components/Assistance1';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import './App.css'; // Include your global styles

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/vans" element={<Vans />} />
        <Route path="/van1" element={<Van1 />} />
        <Route path="/van2" element={<Van2 />} />
        <Route path="/van3" element={<Van3 />} />
        <Route path="/van4" element={<Van4 />} />
        <Route path="/car1" element={<Car1 />} />
        <Route path="/car2" element={<Car2 />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/privacy-policy-site" element={<PrivacyPolicySite />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/vanspage" element={<VansPage />} />
        <Route path="/carspage" element={<CarsPage />} />
        <Route path="/trailersection" element={<TrailerSection />} />
        <Route path="/services" element={<Services />} />
        <Route path="/trailer1" element={<Trailer1 />} />
        <Route path="/roadassistance" element={<RoadAssistancePage />} />
        <Route path="/assistance1" element={<Assistance1 />} />
      </Routes>
      <Footer />
      <CookieBanner /> {/* Add Cookie Banner component */}
    </Router>
  );
};

const CookieBanner = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setIsBannerVisible(true); // Show banner if no consent found
    }
  }, []);

  const acceptCookies = () => {
    // Save the consent in localStorage
    localStorage.setItem('cookieConsent', 'true');
    setIsBannerVisible(false); // Hide the banner
  };

  if (!isBannerVisible) return null; // Don't render the banner if it's not visible

  return (
    <div id="cookie-banner" style={{
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#f4f4f4',
      padding: '10px',
      textAlign: 'center',
      boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
      boxSizing: 'border-box', // Ensures padding is included in width calculation
    }}>
      <p style={{
        margin: '0',
        fontSize: '14px',
        color: '#000',
        overflowWrap: 'break-word', // Helps text to wrap and not overflow
        wordWrap: 'break-word', // Ensures compatibility across browsers
        hyphens: 'auto', // Hyphenates words when breaking at appropriate points
        padding: '0 10px', // Add some padding to avoid edge cut-off
      }}>
        Този уебсайт използва бисквитки за подобряване на вашето преживяване. Продължавайки да използвате сайта, вие се съгласявате с нашата <a href="/privacy-policy-site" style={{ color: '#007BFF', textDecoration: 'underline' }}>политика за поверителност</a>.
      </p>
      <button onClick={acceptCookies} style={{
        padding: '5px 10px',
        backgroundColor: '#ffc000',
        color: '#000',
        border: 'none',
        cursor: 'pointer',
        fontWeight: 'bold',
        marginTop: '5px', // Adds a little space between text and button
      }}>
        Приемам
      </button>
    </div>
  );
};

export default App;
