import React, { useState } from 'react';
import { Box, Typography, Container, Paper, TextField, Button, Alert } from '@mui/material';
import { validate as validateEmail } from 'email-validator';
import styles from '../styles/Contact.module.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    honeypot: '', // Honeypot field
    mathAnswer: '',
  });

  const [errors, setErrors] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);

  // Random math question for basic anti-bot check
  const [num1] = useState(Math.floor(Math.random() * 10) + 1);
  const [num2] = useState(Math.floor(Math.random() * 10) + 1);

  const validate = () => {
    let tempErrors = {};
    if (!formData.name) {
      tempErrors.name = 'Името е задължително. Моля, въведете вашето име.';
    }
    if (!formData.email) {
      tempErrors.email = 'Имейлът е задължителен. Моля, въведете вашия имейл адрес.';
    } else if (!validateEmail(formData.email)) {
      tempErrors.email = 'Невалиден имейл адрес. Моля, въведете валиден имейл адрес.';
    }
    if (!formData.phone) {
      tempErrors.phone = 'Телефонният номер е задължителен. Моля, въведете вашия телефонен номер.';
    } else if (!/^[0-9+\-\s()]{7,15}$/.test(formData.phone)) {
      tempErrors.phone = 'Невалиден телефонен номер. Моля, въведете валиден телефонен номер.';
    }
    if (!formData.message) {
      tempErrors.message = 'Съобщението е задължително. Моля, въведете вашето съобщение.';
    }
    if (formData.honeypot) {
      tempErrors.honeypot = 'Невалиден опит за изпращане на формуляра.';
    }
    if (formData.mathAnswer === '') {
      tempErrors.mathAnswer = 'Моля, отговорете на въпроса.';
    } else if (parseInt(formData.mathAnswer, 10) !== num1 + num2) {
      tempErrors.mathAnswer = `Невалиден отговор на математическия въпрос. Трябва да е ${num1 + num2}.`;
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const response = await fetch('https://rentaro.bg/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams(formData).toString(), // Serialize form data for PHP
      });

      if (response.ok) {
        setFormSubmitted(true);
        setFormData({
          name: '',
          email: '',
          phone: '',
          message: '',
          honeypot: '',
          mathAnswer: '',
        });
      } else {
        setErrors({ submit: 'Грешка при изпращане на съобщението. Моля, опитайте отново.' });
      }
    } catch (error) {
      setErrors({ submit: 'Грешка при връзка със сървъра. Моля, опитайте отново.' });
    }
  };

  return (
    <Container className={styles.container}>
      <Paper className={styles.contactPaper} elevation={3}>
        <Typography variant="h3" gutterBottom>
          Контакти
        </Typography>
        <Typography variant="body1" paragraph>
          Свържете се с нас по телефона, имейл или чрез формата за контакт по-долу.
        </Typography>
        <Typography variant="body1">
          <strong>Телефон:</strong> <a href="tel:+359888078383" className={styles.contactLink}>0888 07 83 83</a><br />
          <strong>Имейл:</strong> <a href="mailto:rent@rentaro.bg" className={styles.contactLink}>rent@rentaro.bg</a><br />
          <strong>Адрес:</strong> <a href="https://maps.google.com/?q=ул.+Гаганица+89а,+Пловдив,+Bulgaria" target="_blank" rel="noopener noreferrer" className={styles.contactLink}>Пловдив, ул. Гаганица 89а</a>
        </Typography>
        {formSubmitted && <Alert severity="success">Съобщението беше изпратено успешно!</Alert>}
        {errors.submit && <Alert severity="error">{errors.submit}</Alert>}
        <Box component="form" className={styles.form} onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Име"
            variant="outlined"
            margin="normal"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
          <TextField
            fullWidth
            label="Имейл"
            type="email"
            variant="outlined"
            margin="normal"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            fullWidth
            label="Телефонен номер"
            type="tel"
            variant="outlined"
            margin="normal"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={!!errors.phone}
            helperText={errors.phone}
          />
          <TextField
            fullWidth
            label="Съобщение"
            variant="outlined"
            margin="normal"
            multiline
            rows={4}
            name="message"
            value={formData.message}
            onChange={handleChange}
            error={!!errors.message}
            helperText={errors.message}
          />
          {/* Honeypot field (hidden) */}
          <TextField
            type="text"
            name="honeypot"
            value={formData.honeypot}
            onChange={handleChange}
            style={{ display: 'none' }}
          />
          {/* Simple Math Challenge */}
          <TextField
            fullWidth
            label={`Колко е ${num1} + ${num2}?`}
            variant="outlined"
            margin="normal"
            name="mathAnswer"
            value={formData.mathAnswer}
            onChange={handleChange}
            error={!!errors.mathAnswer}
            helperText={errors.mathAnswer}
          />
          <Button className={styles.button} type="submit">
            Изпрати
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Contact;
