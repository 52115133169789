import assistance1 from '../assets/images/photo33.jpeg';
import assistanceGallery0 from '../assets/images/photo33.jpeg';
import assistanceGallery1 from '../assets/images/photo34.jpeg';
import assistanceGallery2 from '../assets/images/photo35.jpeg';
import assistanceGallery3 from '../assets/images/photo36.jpeg';

const assistanceData = [
  {
    id: 1,
    name: 'Mercedes Sprinter 412D',
    year: 'Година на производство: 1997',
    license: 'Необходима категория шофьорска книжка: B',
    deposit: 'Депозит: 500 лв.',
    price: 'Наем за 24ч: 150 лв. (без ДДС)',
    range: 'Пробег за 24ч: 350 км.',
    mainImage: assistance1,
    galleryImages: [assistanceGallery0, assistanceGallery1, assistanceGallery2, assistanceGallery3],
    specifications: [
      {
        title: 'Спецификации:',
        details: [
          'Дължина на платформата: 5.0 м.',
          'Широчина на платформата: 2.05 м.',
          'Либетка: 12000 либри.',
          'Товаримост: 1400 кг.',
        ],
      },
      {
        title: 'Технически характеристики:',
        details: [
          'Задвижване: Задно предаване',
          'Вид гориво: Дизел',
          'Скоростна кутия: Механична',
          'Брой врати: 2',
          'Брой места: 3',
        ],
      },
    ],
  },
];

export default assistanceData;
