// src/components/TrailerSection.js
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import trailerData from '../data/trailerData'; // Import trailer data
import styles from '../styles/CarsPage.module.css'; // Use the correct CSS module

const TrailerSection = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>Налични Ремаркета</Typography>
      <Grid container spacing={4}>
        {trailerData.map((trailer) => (
          <Grid item xs={12} sm={6} md={4} key={trailer.id}>
            <Card className={styles.card}>
              <Link to={`/trailer${trailer.id}`} className={styles.link}>
                <CardMedia
                  component="img"
                  alt={trailer.name}
                  height="350"
                  src={trailer.mainImage} // Use src attribute
                  className={styles.cardImage}
                />
                <CardContent>
                  <Typography variant="h5" className={styles.cardTitle}>{trailer.name}</Typography>
                  <Button variant="contained" className={styles.button}>Виж повече</Button>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TrailerSection;
