import React from 'react';
import { Box, Typography, Container, Paper, Grid, Card, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import { motion } from 'framer-motion';
import { styled } from '@mui/system';

const MotionPaper = styled(motion.div)({
  padding: '2rem',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  borderRadius: '10px',
  marginTop: '2rem',
  color: 'white',
});

const vansData = [
  {
    id: 1,
    name: 'Luxury Van',
    image: 'luxury-van.jpg',
    description: 'A luxurious van with all the amenities you need.',
  },
  {
    id: 2,
    name: 'Economy Van',
    image: 'economy-van.jpg',
    description: 'A budget-friendly van that does not compromise on comfort.',
  },
  {
    id: 3,
    name: 'Family Van',
    image: 'family-van.jpg',
    description: 'A spacious van perfect for family trips.',
  },
];

const Vans = () => (
  <Container>
    <MotionPaper
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, ease: 'easeOut' }}
    >
      <Typography variant="h2" gutterBottom>
        Our Fleet
      </Typography>
      <Grid container spacing={4}>
        {vansData.map((van) => (
          <Grid item xs={12} md={4} key={van.id}>
            <Card sx={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', backdropFilter: 'blur(5px)' }}>
              <CardMedia
                component="img"
                alt={van.name}
                height="140"
                image={`../assets/${van.image}`}
              />
              <CardContent>
                <Typography variant="h5" component="div">
                  {van.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {van.description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small" color="primary">
                  Learn More
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </MotionPaper>
  </Container>
);

export default Vans;
