import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import vanData from '../data/vanData'; // Import data for vans
import styles from '../styles/VansPage.module.css'; // CSS module for styling

const VansPage = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>Товарни Бусове</Typography>
      <Grid container spacing={4}>
        {vanData.map((van) => (
          <Grid item xs={12} sm={6} md={4} key={van.id}>
            {/* Wrap the entire card with a Link to make it clickable */}
            <Link to={`/van${van.id}`} className={styles.link} style={{ textDecoration: 'none' }}>
              <Card className={styles.card}>
                <CardMedia
                  component="img"
                  alt={van.name}
                  height="200"
                  image={van.mainImage}
                  className={styles.cardImage}
                />
                <CardContent>
                  <Typography variant="h5" className={styles.cardTitle}>{van.name}</Typography>
                  <Typography variant="body2" color="textSecondary" className={styles.cardSubtitle}>
                    {van.year}
                  </Typography>
                  {/* Keep the button and make it functional separately */}
                  <Link to={`/van${van.id}`} className={styles.buttonLink}>
                    <Button variant="contained" className={styles.button}>Виж повече</Button>
                  </Link>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VansPage;
