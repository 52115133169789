import React from 'react';
import { Box, Typography, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from '../styles/Footer.module.css';
import logo from '../assets/images/ENTARO.png';

const Footer = () => {
  return (
    <Box className={styles.footer}>
      <Box className={styles.footerContent}>
        <Box className={styles.footerSection}>
          <img src={logo} alt="Rentaro Logo" className={styles.logo} />
        </Box>
        <Box className={styles.footerSection}>
          <Typography variant="h6" className={styles.sectionTitle}>Информация</Typography>
          <ul className={styles.list}>
            <li><Link to="/about" className={styles.link}>За нас</Link></li>
            <li><Link to="/policy" className={styles.link}>Общи условия</Link></li>
            <li><Link to="/privacy-policy" className={styles.link}>Политика за поверителност</Link></li>
            <li><Link to="/terms-and-conditions" className={styles.link}>Общи условия за ползване</Link></li> {/* Добавен линк към Общи условия за ползване */}
            <li><Link to="/privacy-policy-site" className={styles.link}>Политика за поверителност на сайта</Link></li> {/* Добавен линк към Политика за поверителност на сайта */}
            <li><Link to="/contact" className={styles.link}>Свържете се с нас</Link></li>
          </ul>
        </Box>
        <Box className={styles.footerSection}>
          <Typography variant="h6" className={styles.sectionTitle}>Контакти</Typography>
          <ul className={styles.list}>
            <li>Пловдив, ул. Гаганица 89а</li>
            <li><MuiLink href="tel:+359888078383" className={styles.link}>+359 888 07 83 83</MuiLink></li>
            <li><MuiLink href="mailto:rent@rentaro.bg" className={styles.link}>rent@rentaro.bg</MuiLink></li>
            <li>Понеделник – Неделя 08:00 – 18:00</li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
