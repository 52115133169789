// src/pages/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicySite = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Политика за поверителност</h1>
      <p>Последно обновена: 14 септември 2024</p>
      <p>Тази Политика за поверителност обяснява как "Рентаро" събира, използва и защитава вашите лични данни при използването на нашия уебсайт rentaro.bg.</p>
      <h2>Каква информация събираме</h2>
      <ul>
        <li><strong>Лична информация:</strong> Име, имейл адрес, телефонен номер, адрес.</li>
        <li><strong>Автоматично събрана информация:</strong> IP адрес, тип на браузъра, операционна система, информация за устройствата.</li>
        <li><strong>Бисквитки:</strong> Използваме бисквитки за подобряване на потребителското изживяване и за анализ на трафика.</li>
      </ul>
      {/* Добавете останалото съдържание от шаблона тук */}
    </div>
  );
};

export default PrivacyPolicySite;
