import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CustomLightbox from './CustomLightbox';
import vanData from '../data/vanData';
import styles from '../styles/VanSection.module.css';

const Van1 = () => {
  const van = vanData.find(v => v.id === 1); // Find the van with id 1
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h4">{van.name.toUpperCase()}</Typography>
        <Typography variant="subtitle1">Товарни Бусове</Typography>
        <Box className={styles.navLinks}>
          <Link to="/van1">Renault Master 2.3 DCI</Link>
          <Link to="/van2">Iveco Daily 35C12</Link>
          <Link to="/van3">Volkswagen Crafter 2.0 TDI</Link>
          <Link to="/van4">Citroen Berlingo</Link>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} position="relative">
            <img
              src={van.mainImage}
              alt={van.name}
              className={styles.vanImage}
              onClick={() => openLightbox(0)}
            />
            <FullscreenIcon
              className={styles.fullscreenIcon}
              onClick={() => openLightbox(0)}
            />
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h5" gutterBottom>
              {van.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {van.year}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {van.license}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.price}>{van.price}</span>
            </Typography>
            <Typography variant="body1" gutterBottom>
              {van.descr}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {van.descr1}
            </Typography>
            <Typography variant="body1" gutterBottom style={{ marginTop: '10px' }}>
              <span className={styles.deposit}>Депозит: {van.deposit}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.tableContainer}>
        {van.specifications.map((spec, index) => (
          <Card key={index} className={styles.table}>
            <CardContent>
              <Typography variant="h4" className={styles.tableHeader}>
                {spec.title}
              </Typography>
              <ul>
                {spec.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Typography variant="h5" className={styles.galleryTitle}>
        ГАЛЕРИЯ
      </Typography>
      <Box className={styles.gallery}>
        {van.galleryImages.map((image, index) => (
          <Box
            key={index}
            className={styles.galleryItem}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => openLightbox(index)}
          >
            <FullscreenIcon className={styles.galleryfullscreenIcon} />
          </Box>
        ))}
      </Box>
      {lightboxOpen && (
        <CustomLightbox
          images={van.galleryImages}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default Van1;
