// src/components/Policy.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import policyDoc from '../assets/documents/rental_policy.docx'; // Ensure the document is in the correct path
import styles from '../styles/Policy.module.css';

const Policy = () => {
  return (
    <Box className={styles.policyContainer}>
      <Typography variant="h4" align="center" gutterBottom>
        Общи условия за отдаване под наем на автомобил
      </Typography>
      <Box className={styles.policyContent}>
        <Typography variant="body1" paragraph>
          {/* Insert the policy content here. This is a summary for display purposes. */}
          <div className='policy-container'>
<p>ОБЩИ УСЛОВИЯ ЗА ОТДАВАНЕ ПОД НАЕМ НА АВТОМОБИЛ</p>
<p>1. ПРЕДАВАНЕ НА АВТОМОБИЛА</p>
<p>1.1. Автомобилът се предава на НАЕМАТЕЛЯ в пълна изправност и добро експлоатационно-техническо състояние, отговарящо на всички изисквания за безопасност на движението, пълно окомплектован – контактен ключ, регистрационен талон, талон за преминат годишен технически преглед, годишна винетка. Автомобилът е застрахован при условията на: „ПЪЛНО АВТОКАСКО“ и Задължителна застраховка „Гражданска отговорност“.</p>
<p>1.2. При наемане на автомобила, НАЕМАТЕЛЯТ е длъжен да провери неизправностите по автомобила и ако установи такива, веднага да уведоми НАЕМОДАТЕЛЯ. Рекламации относно състоянието на автомобила се правят най-късно до предаването му от НАЕМОДАТЕЛЯ.</p>
<p>1.3. При връщане на автомобила, НАЕМОДАТЕЛЯТ прави оглед на същия. Ако се констатират липси или повреди по автомобила НАЕМАТЕЛЯТ дължи тяхното заплащане по оценка на вещо лице на застрахователя или с договорка между страните (НАЕМОДАТЕЛ-НАЕМАТЕЛ) с оглед актуалните пазарни цени.</p>
<p>2. ПРАВА И ЗАДЪЛЖЕНИЯ НА НАЕМОДАТЕЛЯ</p>
<p>НАЕМОДАТЕЛЯТ има право:</p>
<p>2.1. Предварително, след подписване на настоящия договор/след подписване на протокола за предаване на автомобила, да получава уговорения в този договор наем за ползване на автомобила;</p>
<p>2.2. Да проверява дали наетият автомобил се използва по предназначението на този договор без да пречи на НАЕМАТЕЛЯ;</p>
<p>2.3. Да изисква от НАЕМАТЕЛЯ информация за експлоатационно-техническото състояние на наетия автомобил.</p>
<p>2.4. Да изземе обратно наетият автомобил ведно с всички обезщетения, свързани с неизпълнението на задълженията по Договора от страна на НАЕМАТЕЛЯ.</p>
<p>НАЕМОДАТЕЛЯТ е длъжен:</p>
<p>2.5. Да предостави наетия описания в Чл.1 от Договора автомобил в добро експлоатационно-техническо състояние, почистен след автомивка, ведно с всички принадлежности, числящи се към него, след подписване на този договор, както и задължителните документи към него – регистрационен талон, застраховка „Гражданска отговорност“ и талон за годишен технически преглед.</p>
<p>2.6. За предоставянето на автомобила съгласно настоящия текст НАЕМОДАТЕЛЯТ съставя в присъствието на НАЕМАТЕЛЯ приемо-предавателен протокол – Приложение №1 (неразделна част от договора), в който се удостоверява предаването на автомобила в надлежното състояние: Почистен автомобил, горивна наличност (пълен резервоар), съпровождащи документи и др. забележки ако има такива по автомобила.</p>
<p>2.7. Да осигури спокойно ползване на наетия автомобил от страна на НАЕМАТЕЛЯ за срока и действието на настоящия договор.</p>
<p>2.8 При повреда на автомобила, която не може да се вмени във вина на НАЕМАТЕЛЯ, НАЕМОДАТЕЛЯТ се задължава да предостави на НАЕМАТЕЛЯ подходящ заместващ автомобил. При обективна невъзможност за изпълнение на това задължение, договорът се прекратява, считано от датата на постъпване на автомобила за ремонт.</p>
<p>3. ПРАВА И ЗАДЪЛЖЕНИЯ НА НАЕМАТЕЛЯ</p>
<p>НАЕМАТЕЛЯТ има право:</p>
<p>3.1. Да получи автомобила в добро техническо състояние и с редовни документи.</p>
<p>3.2. Да ползва спокойно автомобила според неговото предназначение за срока на договора.</p>
<p>НЕМАТЕЛЯТ е длъжен:</p>
<p>3.3. Да заплаща на НАЕМОДАТЕЛЯ уговореното в този договор възнаграждение в определените с договора и настоящите Общи условия размера и в срокове.</p>
<p>3.4. Да се грижи за автомобила с грижата на добър стопанин като предпочита неговото запазване пред запазване на своите вещи, да опазва автомобила от посегателства, като го паркира на охраняем паркинг или в гараж, както и да го върне в добро техническо състояние и комплектованост на уговорената крайна дата за ползване.</p>
<p>3.5. Да не преотдава и преотстъпва ползването на автомобила на трети лица, включително и членове на семейството му, без изричното съгласие на НАЕМОДАТЕЛЯ, изразено писмено.</p>
<p>3.6. Да уведомява незабавно НАЕМОДАТЕЛЯ за всички възникнали проблеми по време на ползването, включително технически повреди или посегателства от страна на трети лица.</p>
<p>3.7. Да не управлява наетия автомобил след употреба на алкохол или упойващо вещество. В случай на настъпило ПТП с автомобила, при което се констатира от контролните органи употреба на алкохол или друго упойващо вещество от водача на автомобила, независимо от това, чия е вината за ПТП, НАЕМАТЕЛЯТ е длъжен да заплати на НАЕМОДАТЕЛЯ всички разходи за ремонта на наетия автомобил плюс размера на наема за всеки ден, в който наетия автомобил не може да бъде използван.</p>
<p>3.8. Да не оставя в автомобила предадените му документи за регистрация, застраховка „Гражданска отговорност“, талон за годишен технически преглед (ГТП) и контактен ключ. В противен случай, при кражба на автомобила ведно с посочените документи, НАЕМАТЕЛЯТ дължи на НАЕМОДАТЕЛЯ пълния размер на застрахователната сума по застраховка „АВТОКАСКО“ на автомобила, по оценка на вещото лице на застрахователната компания или по договорка между двете страни (НАЕМАТЕЛ - НАЕМОДАТЕЛ) според средната пазарна цена на автомобила към деня на събитието, както и разликата до реалната пазарна стойност на автомобила.</p>
<p>3.9. В случай на ПТП с наетия автомобил, НАЕМАТЕЛЯТ е длъжен да иска съставянето на протокол от органите на МВР и да представи същия на НАЕМОДАТЕЛЯ. При непредставянето на такъв протокол, НАЕМАТЕЛЯТ заплаща на НАЕМОДАТЕЛЯ всички разноски по покриването на щетата. Когато по вина на НАЕМАТЕЛЯ настъпи увреда при ползването /управлението/ на автомобила при обстоятелства, при които застрахователната компания откаже изплащане на обезщетение /употреба на алкохол или други упойващи субстанции, забранени от закона, както и при обсебване и др., предвидени в НК случаи, съставляващи престъпление/, то НАЕМАТЕЛЯТ отговаря за пълното обезщетение в размера на оценената от вещото лице на застрахователя щета /или по пазарната цена към деня на събитието/, както и за разноските по оценката и отстраняването на повредата до възстановяването на автомобила в първоначалното експлоатационно-техническо състояние, в това число и за състоянието на външен и вътрешен интериор, при което НАЕМОДАТЕЛЯТ е предоставил автомобила.</p>
<p>3.10. В случай на претърпяно ПТП, друга щета и във всеки случай, налагащ спирането на автомобила от движение, пряка и непосредствена последица от ползването /управлението/ на автомобила от НАЕМАТЕЛЯ, НАЕМАТЕЛЯТ дължи на НАЕМОДАТЕЛЯ наемната цена за всеки ден в който автомобилът за отдаване под наем не е работил по предназначение.</p>
<p>3.11. С настоящия договор НАЕМАТЕЛЯТ се счита за  предупреден и получил указания от НАЕМОДАТЕЛЯ да спазва правилата за движение по пътищата, както и всички законови ограничения на пътя съгласно ЗДвП. НАЕМАТЕЛЯТ от своя страна е длъжен да спазва правилата за движение по пътищата, както и всички ограничения на пътя съгласно ЗДвП. НАЕМОДАТЕЛЯТ не носи отговорност за извършени от НАЕМАТЕЛЯ нарушения на ЗДвП по време на ползване на автомобила, както и за снимки, констатиращи превишена от допустимата скорост от стационарни или мобилни камери на органите на МВР, както и превозваните от него стоки, вещи и товари.</p>
<p>3.12. Да не превозва товари с общо тегло над 1,5 т. в автомобила.</p>
<p>3.13. С подписването на настоящия договор НАЕМАТЕЛЯТ ДЕКЛАРИРА, ЧЕ НЯМА ДА ПРЕВОЗВА ЗАБРАНЕНИ ОТ ЗАКОНА СТОКИ И ТОВАРИ, КАКТО И КОНТРАБАНДНИ СТОКИ И МИГРАНТИ.</p>
<p>3.14. Да не надвишава максимално разрешената скорост от 120 км/ч, зададена от НАЕМОДАТЕЛЯ. При надвишаване на скоростта, НАЕМАТЕЛЯ дължи неустойка в размер на 50 лв. за всяко установено нарушение. НАЕМОДАТЕЛЯТ има право да прихване сумите по предходното изречение от депозита.</p>
<p>Гориво</p>
<p>3.15. НАЕМАТЕЛЯ е длъжен да зарежда автомобила за своя сметка. НАЕМОДАТЕЛЯТ не отговаря за наличността на горивото в автомобила, докато същият е във владението на НАЕМАТЕЛЯ. В случай, че поради свършване на горивото или каквато и да е друга причина от естество, свързано със стопанисването и управлението на автомобила от НАЕМАТЕЛЯ, бъдат сторени разходи за ПЪТНА ПОМОЩ по репатрирането на автомобила до обслужващия НАЕМОДАТЕЛЯ сервиз, то тези разходи са за сметка на НАЕМАТЕЛЯ. При зареждане с погрешно гориво, НАЕМАТЕЛЯТ заплаща стойността на един резервоар гориво, таксата за ремонт и репатриране на автомобила.</p>
<p>Почистване</p>
<p>3.16. НАЕМОДАТЕЛЯТ предоставя на НАЕМАТЕЛЯ автомобила след почистване в автомивка. В същото състояние НАЕМАТЕЛЯТ следва да върне автомобила, а в случай, че няма възможност да организира това, то при връщане на автомобила НАЕМАТЕЛЯТ заплаща цената за услугата в размер на 20,00 лв. и стойността на горивото, което обстоятелство се отразява в Приемо-предавателния протокол – Приложение №1.</p>
<p>3.17. Да осигури опазване на автомобила от посегателства, като го паркира само на охраняем паркинг или гараж с включена алармена система /ако автомобилът е снабден с такава/ и други налични технически средства за защита, като вземе всички предпазни мерки.</p>
<p>3.18. След изтичане на уговорения срок, както и при предсрочно прекратяване на договора, да върне автомобила на указаното място в рамките на установеното от НАЕМОДАТЕЛЯ работно време.</p>
<p>3.19. Ако желае продължаване на срока на договора, НАЕМАТЕЛЯТ е длъжен да уведоми за това НАЕМОДАТЕЛЯ най-малко 12 часа преди изтичане на срока на договора, като в този случай удължаването на срока на договора е валидно единствено с подписване на АНЕКС между страните.</p>
<p>3.20. Да обезщети НАЕМОДАТЕЛЯ за всички претърпени щети, липси и пропуснати ползи изцяло ако:</p>
<p>Отстъпи управлението на автомобила на лице без свидетелство за управление или за което няма изрично писмено съгласие от НАЕМОДАТЕЛЯ;</p>
<p>Автомобилът е управляван от водач, употребил алкохол или друго упойващо вещество;</p>
<p>При настъпило застрахователно събитие не осигури или не представи своевременно всички необходими документи за защита интересите на НАЕМОДАТЕЛЯ пред застрахователя, включително писмен полицейски протокол за събитието;</p>
<p>Използва автомобила за извършване на умишлено престъпление, надлежно установено по предвидения от закона ред;</p>
<p>Автомобилът е използван за транспортиране на пътници и товари срещу възнаграждение, за транспортиране на забранени от закона стоки или е управляван извън пътните настилки;</p>
<p>Автомобилът е използван за теглене на друг автомобил, за състезателна дейност или учебни цели;</p>
<p>Извършени са неразрешени от НАЕМОДАТЕЛЯ ремонти по автомобила;</p>
<p>За всички по-горе изброени точки настъпилото събитие не се покрива от застраховката, съгласно общите условия към застрахователната полица и НАЕМАТЕЛЯТ дължи обезщетение на НАЕМОДАТЕЛЯ при причинени вреди и пропуснати ползи;</p>
<p>3.21. Да обезщети НАЕМОДАТЕЛЯТ при всеки случай на констатирани щети по автомобила, които не са резултат от обичайна употреба и нормално износване, ако това не е покрито от застраховател или е уговорено друго.</p>
<p>3.22. НАЕМАТЕЛЯТ дължи пълно обезщетение във всеки случай на:</p>
<p>Частична щета, кражба и/или липса на: елементи на автомобила, чистачки, касетофон, навигационна система, антена, гуми, джанти, тасове и щети по ходовата част и двигателя на автомобила;</p>
<p>Загуба или повреда на регистрационни табели, документи, ключове и алармени системи, липсващи съоръжения или друго оборудване, неразделна част от автомобила;</p>
<p>Повредени интериорни детайли; </p>
<p>Замърсена тапицерия, нетърпима миризма на цигарен дим и други замърсявания по интериора и екстериора на автомобила, изискващи допълнително специално почистване;</p>
<p>4. ПРЕКРАТЯВАНЕ НА ДОГОВОРА</p>
<p>Договорът се прекратява в следните случаи:</p>
<p>4.1. С изтичане срока на договора.</p>
<p>4.2. По взаимно съгласие на страните, изразено писмено и при уреждане на всички финансови въпроси, свързани с ползването на автомобила.</p>
<p>4.3. Предсрочно от НАЕМОДАТЕЛЯ, ако автомобилът не се използва по предназначение и в нарушение на настоящия договор.</p>
<p>4.4. При грубо нарушение на задълженията на НАЕМАТЕЛЯ за опазване на автомобила, по преценка на НАЕМОДАТЕЛЯ, че се застрашава или унищожава автомобила в резултат на груба небрежност от страна на НАЕМАТЕЛЯ, то същият е длъжен да предаде автомобила и документацията при поискване от НАЕМОДАТЕЛЯ.</p>
<p>4.5. С изтичане на 5 часа от крайния срок за връщане на автомобила, ако НАЕМАТЕЛЯТ не е уведомил и подписал АНЕКС за удължаване на договора, той се смята за прекратен по право и НАЕМАТЕЛЯТ дължи незабавно връщане на автомобила. В този случай, при неизпълнение НАЕМОДАТЕЛЯТ има право да поиска от органите на МВР да обявят автомобила за национално издирване, подава сигнал в Прокуратурата за обсебване на автомобила, а НАЕМАТЕЛЯТ дължи неустойка на НАЕМОДАТЕЛЯ в размер на наемната цена, за всеки ден забава. Неустойката по предходното изречение се дължи от деня в който НАЕМАТЕЛЯ е трябвало да предаде обратно автомобила до момента на неговото обратно предаване на НАЕМОДАТЕЛЯ. Тази неустойка се уговаря за обезщетяване на вреди от неизпълнението, поради разваляне на договора, на което основание настоящата разпоредба обвързва страните и след момента на развалянето.</p>
<p>5. ДРУГИ УСЛОВИЯ</p>
<p>5.1. Допълнителен шофьор – трябва да отговоря на изискванията за минимална възраст и се посочват писмено при сключване на договора.</p>
<p>5.2. Цена – цената е базирана на 24-часов наем. Всеки ден започва от 0:00 часа. Всеки започнат ден се счита за пълен, без значение на връщане на наетия автомобил.</p>
<p>5.3. Доставка и/или прибиране – услугата е на стойност 10 лв. в границите на гр. Пловдив, а извън него 1 лв. на км до точката на доставка и/или прибиране.</p>
<p>5.4. Шофьорска книжка – НАЕМАТЕЛЯТ и допълнителните шофьори трябва да притежават валидна шофьорска книжка, издадена най-малко от една година. Изисква се международна шофьорска книжка за свидетелства за управление, които не са на Латиница</p>
<p>5.5. Такса гориво – ако автомобилът е върнат с липсващо гориво се заплаща 10 лв. такса презареждане и цената на липсващото гориво по 3.00 лв. на литър.</p>
<p>5.6. Специално оборудване – предоставя се по допълнителна уговорка на страните и може да включва - вериги за сняг, навигационна система, мобилно интернет устройство, бебешко столче и др.</p>
<p>5.7. Пътни нарушения и претенции на трети лица – при глоби или всякакви други пътни нарушения или каквито и да било други претенции на трети лица, свързани с или основани на действие или поведение на НАЕМАТЕЛЯ, отправени и/или търсени/събирани от НАЕМОДАТЕЛЯ, НАЕМАТЕЛЯ следва да заплати незабавно след уведомлението от страна на НАЕМОДАТЕЛЯ. В случай на забава, за всеки ден от забавата, НАЕМАТЕЛЯТ дължи неустойка за забава в размер на законната лихва начислявана върху съответна дължима сума до окончателното заплащане на тази сума, ведно с неустойка за обезщетение на сторени и административни и правни разноски в размер на 300 лв.</p>
<p>5.8. Застраховки – при нанесени щети, в случай на кражба на наетия автомобил или част от него, НАЕМАТЕЛЯ е изцяло отговорен до пълната стойност на автомобила.</p>
<p>5.9. Начин на плащане – всички дължими суми се заплащат от НАЕМАТЕЛЯ в лева (BGN) предварително по приложена банкова сметка или в брой на място при получаване на автомобила.</p>
<p>5.10. Банкова сметка - IBAN: BG97 UBBS 8002 1051 3188 50, BIC: UBBSBGSF, Обединена Българска Банка АД.</p>
<p>5.11. За всяка заплатена от НАЕМАТЕЛЯ сума НАЕМОДАТЕЛЯТ издава фактура.</p>
<p>5.12. При забавено връщане на автомобила с повече от 1 ч. след крайния срок, вписан в Договора за наем, без да е уведомен НАЕМОДАТЕЛЯТ, същият дължи неустойка в размер на наемната цена на ден, за всеки просрочен ден до връщане на автомобила.</p>
<p>С подписа под настоящия документ НАЕМАТЕЛЯТ потвърждава, че е получил екземпляр от тези Общи условия, прочел е и разбира напълно тяхното съдържание, във връзка с което заявява, че ги приема в цялост и без възражения.</p>
<p>НАЕМАТЕЛ:</p>
<p>Име, Фамилия							Подпис</p>
  </div>
          {/* Add more text or sections as needed */}
        </Typography>
      </Box>
      <Button
        variant="contained"
        href={policyDoc}
        download="rental_policy.docx"
        sx={{
          backgroundColor: '#ffc000', // Set button color to #ffc000
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#e6a700', // Slightly darker color on hover
          },
          textTransform: 'none', // Keep the text case as defined
          fontWeight: 'bold',
          padding: '10px 20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        Изтегляне на документа
      </Button>
    </Box>
  );
};

export default Policy;
