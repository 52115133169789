// src/pages/TermsAndConditions.js
import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Общи условия за ползване на уебсайта "Rentaro.bg"</h1>
      <p>Последно обновени: 14 септември 2024</p>
      <h2>Въведение</h2>
      <p>С използването на уебсайта rentaro.bg вие се съгласявате с тези общи условия. Ако не сте съгласни с тях, моля не използвайте сайта.</p>
      <h2>Използване на услугите</h2>
      <ul>
        <li>Резервации могат да се правят само от лица над 18 години.</li>
        <li>Всички цени и наличности подлежат на потвърждение.</li>
      </ul>
      {/* Добавете останалото съдържание от шаблона тук */}
    </div>
  );
};

export default TermsAndConditions;
