// src/components/About.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import styles from '../styles/Policy.module.css'; // Ensure styles are correctly linked
import VehicleIcons from './VehicleIcons';

const About = () => {
  return (
    <Box className={styles.policyContainer}>
      <Typography variant="h5" align="center" gutterBottom>
        ЗА НАС
      </Typography>
      <Box className={styles.policyContent}>
        <Typography variant="body1" paragraph>
          За вашия бизнес или лични нужди, RENTARO предлага под наем за регион Пловдив, страната и чужбина:
        </Typography>
        <ul className={styles.servicesList}>
          <li>Товарни бусове до 3.5 т.;</li>
          <li>Товарни бусове до 3.5 т. с осигурен шофьор;</li>
          <li>Автомобили;</li>
          <li>Ремаркета;</li>
          <li>Пътна помощ с осигурен шофьор.</li>
        </ul>
        <Typography variant="body1" paragraph>
          Автопаркът на RENTARO е от отлично поддържани и нови транспортни средства, които ще ви осигурят комфорт и сигурност на пътя на изгодна цена. RENTARO предлага професионална услуга и добро обслужване на достъпна цена. Ние държим да направим наемането на транспортното средство възможно най-улеснено. Няма скрити такси и допълнителни условия. Плащате това, което е обявено в сайта ни. Изготвяме и индивидуални оферти по изискване на клиентите.
        </Typography>
        <VehicleIcons />
      </Box>
    </Box>
  );
};

export default About;
