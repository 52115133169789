// src/components/CarsPage.js
import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import carData from '../data/carData'; // Import the car data
import styles from '../styles/CarsPage.module.css'; // CSS module for styling

const CarsPage = () => {
  return (
    <Box className={styles.container}>
      <Typography variant="h5" className={styles.title}>Автомобили</Typography>
      <Grid container spacing={4}>
        {carData.map((car) => (
          <Grid item xs={12} sm={6} md={4} key={car.id}>
            <Card className={styles.card}>
              <Link to={`/car${car.id}`} className={styles.link}>
                <CardMedia
                  component="img"
                  alt={car.name}
                  height="200"
                  image={car.mainImage}
                  className={styles.cardImage}
                />
                <CardContent>
                  <Typography variant="h6" className={styles.cardTitle}>{car.name}</Typography>
                  <Typography variant="body2" color="textSecondary" className={styles.cardSubtitle}>
                    {car.year}
                  </Typography>
                  <Button variant="contained" className={styles.button}>Виж повече</Button>
                </CardContent>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CarsPage;
