import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CustomLightbox from './CustomLightbox';
import trailerData from '../data/trailerData'; // Ensure you have the trailer data in a similar structure as vanData
import styles from '../styles/RoadAssistancePage.module.css'; // Create CSS styles similar to VanSection.module.css

const Trailer1 = () => {
  const trailer = trailerData.find(t => t.id === 1); // Find the trailer with id 1
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h5">{trailer.name.toUpperCase()}</Typography>
        <Typography variant="subtitle1">Ремаркета</Typography>
        <Box className={styles.navLinks}>
          <Link to="/trailer1">STEMA FT 7525</Link>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} position="center">
            <img src={trailer.mainImage} alt={trailer.name} className={styles.trailerImage} onClick={() => openLightbox(0)} />
            <FullscreenIcon className={styles.fullscreenIcon} onClick={() => openLightbox(0)} />
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h5" gutterBottom>
              {trailer.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {trailer.year}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {trailer.license}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.price}>{trailer.price}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.tableContainer}>
        {trailer.specifications.map((spec, index) => (
          <Card key={index} className={styles.table}>
            <CardContent>
              <Typography variant="h5" className={styles.tableHeader}>{spec.title}</Typography>
              <ul>
                {spec.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Typography variant="h5" className={styles.galleryTitle}>
        ГАЛЕРИЯ
      </Typography>
      <Box className={styles.gallery}>
        {trailer.galleryImages.map((image, index) => (
          <Box
            key={index}
            className={styles.galleryItem}
            style={{ backgroundImage: `url(${image})` }} // Use the imported images directly
            onClick={() => openLightbox(index)}
          >
            <FullscreenIcon className={styles.galleryfullscreenIcon} />
          </Box>
        ))}
      </Box>
      {lightboxOpen && (
        <CustomLightbox
          images={trailer.galleryImages} // Use the array of images directly
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default Trailer1;
