import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Icon1 from '../assets/icons/icon1.png';
import Icon2 from '../assets/icons/icon2.png';
import Icon3 from '../assets/icons/icon3.png';
import styles from '../styles/InfoSection.module.css';

const InfoItemsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  flexWrap: 'wrap',
  width: '100%',
  maxWidth: '1200px',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

const InfoItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(3),
  textAlign: 'center',
}));

const Icon = styled('img')({
  width: '80px', // Increased the size of the icons
  height: '80px',
  marginBottom: '10px',
});

const InfoSection = () => (
  <Box className={styles.infoSection}>
    <Box className={styles.infoContent}>
      <Typography variant="h3" component="h2" gutterBottom style={styles.infotext1}>
        ТОВАРНИ БУСОВЕ И АВТОМОБИЛИ ПОД НАЕМ
      </Typography>
      <InfoItemsContainer>
        <InfoItem>
          <Icon src={Icon1} alt="Icon 1" />
          <Typography variant="h5" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Нови Бусове и Автомобили</Typography>
          <Typography variant="body1" style={{ fontFamily: 'Roboto, sans-serif' }}>100% технически изправни</Typography>
        </InfoItem>
        <InfoItem>
          <Icon src={Icon2} alt="Icon 2" />
          <Typography variant="h5" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Гарантирано ниски цени</Typography>
          <Typography variant="body1" style={{ fontFamily: 'Roboto, sans-serif' }}>Гъвкавост при индивидуално договаряне</Typography>
        </InfoItem>
        <InfoItem>
          <Icon src={Icon3} alt="Icon 3" />
          <Typography variant="h5" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Включени застрахователни</Typography>
          <Typography variant="body1" style={{ fontFamily: 'Roboto, sans-serif' }}>покрития и пътни такси</Typography>
        </InfoItem>
      </InfoItemsContainer>
    </Box>
  </Box>
);

export default InfoSection;
