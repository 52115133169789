// src/components/VehicleIcons.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import styles from '../styles/VehicleIcons.module.css'; // Import CSS module
import vanImage from '../assets/icons/8888.png';
import carImage from '../assets/icons/9999.png';
import assist from '../assets/images/photo23.png';
import trailer from '../assets/images/photo24.png';



const VehicleIcons = () => {
  return (
    <Box className={styles.iconContainer}>
      <Link to="/vanspage" className={styles.iconBox}>
      <img src={vanImage} alt="Товарни бусове" className={styles.iconImage} />
      <Typography variant="subtitle1">Товарни Бусове</Typography>
      </Link>
      <Link to="/carspage" className={styles.iconBox}>
      <img src={carImage} alt="Автомобили" className={styles.iconImage} />
        <Typography variant="subtitle1">Автомобили</Typography>
      </Link>
      <Link to="/roadassistance" className={styles.iconBox}>
      <img src={assist} alt="Пътна Помощ" className={styles.iconImage} />
        <Typography variant="subtitle1">Пътна помощ</Typography>
      </Link>
      <Link to="/trailersection" className={styles.iconBox}>
      <img src={trailer} alt="Ремаркета" className={styles.iconImage} />
        <Typography variant="subtitle1">Ремаркета</Typography>
      </Link>
    </Box>
  );
};

export default VehicleIcons;
