import React from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slide from './Slide';
import InfoSection from './InfoSection';
import VehicleIcons from './VehicleIcons';
import image1 from '../assets/images/1111.png';
import image2 from '../assets/images/2222.png';
import image3 from '../assets/images/3333.png';
import image4 from '../assets/images/4444.png';
import image5 from '../assets/images/5555.png';
import overlayTexts from '../data/overlayTexts';
import styles from '../styles/Home.module.css';

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 11000,
  pauseOnHover: false,
  pauseOnFocus: false,
};

const Home = () => (
  <Box>
    <motion.div
      className={styles.heroSection}
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.8, ease: 'easeOut' }}
    >
      <Slider {...sliderSettings} className={styles.slider}>
        {overlayTexts.map((text, index) => (
          <Slide key={index} image={[image1, image2, image3, image4, image5][index]} text={text} />
        ))}
      </Slider>
    </motion.div>
    <InfoSection /> {/* Add InfoSection */}
    <VehicleIcons />
    
  </Box>
);

export default Home;
