import React, { useState } from 'react';
import { Box, Typography, Grid, Card, CardContent } from '@mui/material';
import { Link } from 'react-router-dom';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CustomLightbox from './CustomLightbox';
import assistanceData from '../data/assistanceData';
import styles from '../styles/RoadAssistancePage.module.css'; // Make sure to create this CSS file or adjust to your existing one

const Assistance = () => {
  const assistance = assistanceData.find(a => a.id === 1);
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setLightboxOpen(true);
  };

  return (
    <Box className={styles.container}>
      <Box className={styles.header}>
        <Typography variant="h4">{assistance.name.toUpperCase()}</Typography>
        <Typography variant="subtitle1">Пътна Помощ</Typography>
        <Box className={styles.navLinks}>
          <Link to="/assistance1">MERCEDES SPRINTER 412D</Link>
        </Box>
      </Box>
      <Box className={styles.section}>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={6} position="relative">
            <img src={assistance.mainImage} alt={assistance.name} className={styles.assistanceImage} onClick={() => openLightbox(0)} />
            <FullscreenIcon className={styles.fullscreenIcon} onClick={() => openLightbox(0)} />
          </Grid>
          <Grid item xs={12} md={6} textAlign="center">
            <Typography variant="h5" gutterBottom>
              {assistance.name}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {assistance.year}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {assistance.license}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.deposit}>{assistance.deposit}</span>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.price}>{assistance.price}</span>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span className={styles.range}>{assistance.range}</span>
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className={styles.tableContainer}>
        {assistance.specifications.map((spec, index) => (
          <Card key={index} className={styles.table}>
            <CardContent>
              <Typography variant="h6" className={styles.tableHeader}>{spec.title}</Typography>
              <ul>
                {spec.details.map((detail, idx) => (
                  <li key={idx}>{detail}</li>
                ))}
              </ul>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Typography variant="h4" className={styles.galleryTitle}>
        ГАЛЕРИЯ
      </Typography>
      <Box className={styles.gallery}>
        {assistance.galleryImages.map((image, index) => (
          <Box
            key={index}
            className={styles.galleryItem}
            style={{ backgroundImage: `url(${image})` }}
            onClick={() => openLightbox(index)}
          >
            <FullscreenIcon className={styles.galleryFullscreenIcon} />
          </Box>
        ))}
      </Box>
      {lightboxOpen && (
        <CustomLightbox
          images={assistance.galleryImages}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
          onClose={() => setLightboxOpen(false)}
        />
      )}
    </Box>
  );
};

export default Assistance;
