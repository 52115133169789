// src/components/PrivacyPolicy.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import privacyDoc from '../assets/documents/privacy_policy.docx'; // Ensure the document path is correct
import styles from '../styles/Policy.module.css';

const PrivacyPolicy = () => {
  return (
    <Box className={styles.policyContainer}>
      <Typography variant="h4" align="center" gutterBottom>
        Политика на поверителност и защита на личните данни
      </Typography>
      <Box className={styles.policyContent}>
        <Typography variant="body1" paragraph>
          <div className='policy-container'>
          <p>Политика на поверителност и защита на личните данни.</p>
<p>Уважаеми клиенти, молим внимателно да прочетете настоящата Политика за поверителност и защита на личните данни на фирма „РЕНТАРО“ ЕООД.</p>
<p>Политиката за поверителност е приложима за всички наши клиенти – физически лица, както и за представителите на клиентите ни юридически лица. Целта на настоящата политика е да разясни каква лична информация събираме и обработваме при сключване на договор, или при приемане на заявка за резервация. За какви цели използваме събраните лични данни и какви са вашите права като субект на лични данни.</p>
<p>В случай че имате въпроси относно начина на защита на личните ви данни, моля, не се колебайте да се свържете с нас на посочените по-долу телефон и електронна поща. </p>
<p>РЕНТАРО ЕООД – дружество регистрирано в Търговския регистър с:</p>
<p>ЕИК: 207260114</p>
<p>ИН по ДДС: BG207260114</p>
<p>Адрес: гр. Пловдив, ул. Гаганица 89а</p>
<p>Телефон: +359 888 07 83 83</p>
<p>Имейл: rent@rentaro.bg</p>
<p>Дефиниции:</p>
<p>„лични данни“ означава всяка информация, свързана с идентифицирано физическо лице или физическо лице,което може да бъде идентифицирано (“субект на данни”); физическо лице, което може да бъде идентифицирано, е  лице, което може да бъде идентифицирано, пряко или непряко, по-специално чрез идентификатор като име, идентификационен номер, данни за местонахождение, онлайн идентификатор или по един или повече признаци, специфични за физическата, физиологичната, генетичната, психическата, умствената, икономическата, културната или социална идентичност на това физическо лице;</p>
<p>„обработване“ означава всяка операция или съвкупност от операции, извършвана с лични данни или набор от лични данни чрез автоматични или други средства като събиране, записване, организиране, структуриране, съхранение, адаптиране или промяна, извличане, консултиране, употреба, разкриване чрез предаване, разпространяване или друг начин, по който данните стават достъпни, подреждане или комбиниране, ограничаване, изтриване или унищожаване;</p>
<p>„ограничаване на обработването” означава маркиране на съхранявани лични данни с цел ограничаване на обработването им в бъдеще;</p>
<p>„администратор” означава физическо или юридическо лице,публичен орган, агенция или друга структура, която сама или съвместно с други определя целите и средствата за обработването на лични данни; </p>
<p>„обработващ лични данни” означава физическо или юридическо лице, публичен орган, агенция или друга структура, която обработва лични данни от името на администратора;</p>
<p>„получател“ означава физическо или юридическо лице, публичен орган, агенция или друга структура, пред която се разкриват личните данни, независимо дали е трета страна или не;</p>
<p>„трета страна“ означава физическо или юридическо лице, публичен орган, агенция или друг орган, различен от субекта на данните, администратора, обработващия лични данни и лицата, които под прякото ръководство на администратора или на обработващия лични данни имат право да обработват личните данни;</p>
<p>„съгласие на субекта на данните“ означава всяко свободно изразено, конкретно, информирано и недвусмислено указание за волята на субекта на данните, посредством изявление или ясно потвърждаващо действие, което изразява съгласието му свързаните с него лични данни да бъдат обработени.</p>
<p>Обработката на личните ви данни се извършва с цел:</p>
<p>установяване на самоличността на клиента;</p>
<p>осъществяване на контакт с клиента във връзка със заявените от него услуги или с цел изпълнение на поетите от нас задължения по сключен с клиента договор;</p>
<p>управление и изпълнение на вашите заявки във връзка със сключването или изпълнението на договор за предоставяните от нас услуги;</p>
<p>изготвяне и изпращане на фактури за дължимите плащания по направените от вас заявки или сключените с нас договори;</p>
<p>осигуряване на необходимото ви цялостно обслужване;</p>
<p>събиране на дължимите суми за ползваните услуги;</p>
<p>извършване на обработка от обработващ данните при сключване на съответен договор с обработващ лични данни.</p>
<p>За изпълнение на законови задължения:</p>
<p>Ние обработваме идентификационните ви данни, данни за изготвяне на договори, фактури и извършване на плащания и други ваши лични данни, с цел изпълнение на нормативно уредени наши задължения, включително, но не само:</p>
<p>предоставяне на информация на Комисията за защита на потребителите или трети лица, предвидени в Закона за защита на потребителите;</p>
<p>предоставяне на информация на органите на реда при нарушения;</p>
<p>предоставяне на информация на Комисията за защита на личните данни;</p>
<p>изпълнение на задължения, предвидени в Закона за счетоводството и Данъчно-осигурителния процесуален кодекс, както и други свързани нормативни актове;</p>
<p>предоставяне на информация на съда и трети лица, в рамките на производство пред съд, съобразно изискванията на приложимите към производството процесуални и материалноправни нормативни актове.</p>
<p>Обръщаме ви внимание, че вашите лични данни се събират и/или обработват за целите на директния маркетинг.</p>
<p>Какви лични данни обработваме:</p>
<p>Данни за идентификация:</p>
<p>трите имена, единен граждански номер или личен номер на чужденец, постоянен адрес;</p>
<p>други данни:</p>
<p>информация за вида и съдържанието на договорното отношение, както и всяка друга информация, свързана с договорното правоотношение, включително:</p>
<p>лични данни за контакт – адрес за контакт, телефонен номер, имейл;</p>
<p>електронна поща и информация за извършените от потребителя заявки, подадени оплаквания, молби за съдействие;</p>
<p>друга обратна информация, която получаваме от вас;</p>
<p>данни, предоставяни през нашата интернет страница;</p>
<p>други лични данни, предоставени от вас или от трето лице при сключване на договор.</p>
          </div>
        </Typography>
      </Box>
      <Button
        variant="contained"
        href={privacyDoc}
        download="privacy_policy.docx"
        sx={{
          backgroundColor: '#ffc000', // Set button color to #ffc000
          color: '#ffffff',
          '&:hover': {
            backgroundColor: '#e6a700', // Slightly darker color on hover
          },
          textTransform: 'none', // Keep the text case as defined
          fontWeight: 'bold',
          padding: '10px 20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
      >
        Изтегляне на документа
      </Button>
    </Box>
  );
};

export default PrivacyPolicy;
