const overlayTexts = [
  {
    heading: "Renault Master 2.3 dCi",
    subHeading: "Товарен Бус",
    priceInfo: "*Цена за наем на ден:",
    price: "80 лв. / 24 часа",
    inf: "(Пробег - 350 км.)",
    deposit: "Депозит: 300 лв.",
    position: 'left',
    link: './van1',
  },
  {
    heading: "Iveco Daily 35C12",
    subHeading: "Товарен Бус",
    priceInfo: "*Цена за наем на ден:",
    price: "100 лв. / 24 часа",
    inf: "(Пробег - 350 км.)",
    deposit: "Депозит: 300 лв.",
    position: 'right',
    link: '/van2',
  },
  {
    heading: "Volkswagen Crafter 2.0 TDI",
    subHeading: "Товарен Бус",
    priceInfo: "*Цена за наем на ден:",
    price: "100 лв. / 24 часа",
    inf: "(Пробег - 350 км.)",
    deposit: "Депозит: 300 лв.",
    position: 'left',
    link: '/van3',
  },
  {
    heading: "Volkswagen Up 1.0i",
    subHeading: "Автомобил",
    priceInfo: "*Цена за наем на ден:",
    price: "50 лв. / 24 часа",
    inf: "(Пробег - 350 км.)",
    deposit: "Депозит: 150 лв.",
    position: 'right',
    link: '/car1',
  },
  {
    heading: "Dacia Sandero 1.0i",
    subHeading: "Автомобил",
    priceInfo: "*Цена за наем на ден:",
    price: "50 лв. / 24 часа",
    inf: "(Пробег - 350 км.)",
    deposit: "Депозит: 150 лв.",
    position: 'right',
    link: '/car2',
  },
];

export default overlayTexts;
