import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import ViberIcon from '@mui/icons-material/Message'; // Placeholder for Viber icon
import WhatsAppIcon from '@mui/icons-material/WhatsApp'; // Importing WhatsApp icon
import styles from '../styles/AnnouncementBar.module.css';

const AnnouncementBar = () => {
  return (
    <Box className={styles.announcementBar}>
      <Box className={styles.contactInfo}>
        <Typography variant="body2" component="span" className={styles.contactText}>
          Tel.: <a href="tel:+359888078383" className={styles.contactLink}>0888 07 83 83</a> | 
          <a href="mailto:rent@rentaro.bg" className={styles.contactLink}>rent@rentaro.bg</a> | 
          <a href="https://maps.google.com/?q=ул.+Гаганица+89а,+Пловдив,+Bulgaria" target="_blank" rel="noopener noreferrer" className={styles.contactLink}>
            Пловдив, ул. Гаганица 89а
          </a>
        </Typography>
      </Box>
      <Box className={styles.socialMediaIcons}>
        <IconButton href="https://www.facebook.com/rentarorentacar" target="_blank" size="small">
          <a className={styles.icontexts}>FACEBOOK</a>
          <FacebookIcon fontSize="small" />
        </IconButton>
        <IconButton href="viber://add?number=359888078383" size="small">
          <ViberIcon fontSize="small" />
          <a className={styles.icontexts}>VIBER</a>
        </IconButton>
        <IconButton href="https://wa.me/359888078383" target="_blank" size="small"> {/* WhatsApp link */}
          <WhatsAppIcon fontSize="small" />
          <a className={styles.icontexts}>WHATSAPP</a>
        </IconButton>
      </Box>
    </Box>
  );
};

export default AnnouncementBar;
