import React from 'react';
import { Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from '../styles/Slide.module.css';

const TextOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '10%', 
  left: '50%',
  transform: 'translate(-50%, 0)', 
  color: '#fff',
  textAlign: 'center',
  zIndex: 2,
  padding: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    top: '50%',
    left: '10%',
    right: 'auto',
    textAlign: 'left',
    transform: 'translateY(-50%)',
    padding: theme.spacing(3),
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    maxWidth: '70%',
    boxShadow: '0 8px 15px rgba(0, 0, 0, 0.5)',
    transition: 'background-color 0.3s',
    animation: 'moveUpAndDown 3s infinite ease-in-out',
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffc000', 
  color: '#ffffff',
  textTransform: 'none',
  fontWeight: 'bold',
  padding: '8px 16px',
  boxShadow: '0 0px 12px rgba(0, 0, 0, 0.1)',
  zIndex: 3,
  '&:hover': {
    backgroundColor: '#e6a700', 
    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
    transform: 'translateY(-3px)',
  },
}));

const Slide = ({ image, text }) => (
  <Box
    className={styles.slide}
    sx={{
      backgroundImage: `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '550px',
      width: '100%',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.55)', 
        zIndex: 1,
      },
    }}
  >
    <TextOverlay>
      <Typography variant="h4" sx={{ color: '#ffffff !important', fontWeight: 'bold', marginBottom: '10px' }}>
        {text.heading}
      </Typography>
      <Typography variant="h6" sx={{ color: '#ffc000 !important', marginBottom: '10px' }}>
        {text.subHeading}
      </Typography>
      <Typography variant="body2" sx={{ color: '#ffffff !important', marginBottom: '10px' }}>
        {text.priceInfo}
      </Typography>
      <Typography variant="body2" sx={{ color: '#ffc000', marginBottom: '10px' }}>
        {text.price}
      </Typography>
      {text.inf && (
        <Typography variant="body2" sx={{ color: '#ffffff', marginBottom: '10px' }}>
          {text.inf}
        </Typography>
      )}
      {text.deposit && (
        <Typography variant="body2" className={styles.deposit}>
          {text.deposit}
        </Typography>
      )}
      <CustomButton component={Link} to={text.link}>
        ВИЖ ОЩЕ
      </CustomButton>
    </TextOverlay>
    <Box className={styles.triangle}></Box>
  </Box>
);

Slide.propTypes = {
  image: PropTypes.string.isRequired,
  text: PropTypes.object.isRequired,
};

export default Slide;
